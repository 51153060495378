.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.box {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
  border: 1px solid #e6ebf1;
  border-radius: .625rem;
  background-color: #fff;
}

.box-content {
  padding: 25px;
  border-bottom: 1px solid #e6ebf1;
}

.ant-timeline-item-content{
  font-weight: 600;
}

.ant-input-prefix{
  margin-right: 10px !important;
}

.app-header .nav-left, .app-header .nav-right{
  align-items: center !important;
}

.ant-upload.ant-upload-drag{
  height: 415px !important;
}

.form-create-event .ant-affix{
  top: 97px !important;
  margin-left: 1.5px;
}

.ant-notification{
  z-index: 99999 !important;
}

.ant-radio-wrapper{
  align-items: center !important;
}

.ant-checkbox-wrapper{
  align-items: center !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow{
  left: 0 !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header{
  padding-left: 20px !important;
}

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-header{
  font-size: 14px !important;
}